<template>
  <div>
    <h1 class="p-text-left p-offset-1">
      Work Experience
    </h1>
    <ScrollPanel style="width: 100%; height: 600px">
        <div>
        <h1 class="p-text-left" id="heading">
        LinkMate, Melbourne, Australia (Dec 2021 -  Mar 2022)
        </h1>
        <div class="p-d-flex p-flex-column" id="experience">
          <div class="p-grid p-jc-between">
            <div class="p-text-left p-col-8">
            <h3 id="sub-heading">Jr FullStack Developer</h3>
            Completed a 3 month internship at 
            <a href="https://linkmate.com.au/" target="_blank">LinkMate</a> as a part of my course at the University of Melbourne 
            <ul>
              <li>
                Collaborated on adding multiple pay-to-use features to the LinkMate app.
              </li>
              <li>
                UI and feature compliance for Apple store approval.
              </li>
              <li>
                Agile cycles utilizing GitHub projects & GitHub CI. 
              </li>
              <li>
                Full-stack development of a hybrid mobile application using Angular, Ionic, Firebase and cloud functions, 
                from design to deployment
              </li>
              
            </ul>
            </div>
          </div>
        </div>
      </div>
      <div>
        <h1 class="p-text-left" id="heading">
        University of Melbourne, Victoria, Melbourne (July 2021 -  Nov 2021)
        </h1>
        <div class="p-d-flex p-flex-column" id="experience">
          <div class="p-grid p-jc-between">
            <div class="p-text-left p-col-8">
            <h3 >Casual Academic Staff</h3>
            Project supervisor for the Computing and Informating undergraduate capstone 
            <a href="https://handbook.unimelb.edu.au/subjects/comp30022" target="_blank">IT project</a> at the University of Melbourne 
            <ul>
              <li>
                Delivered weekly workshops on a range of topics from Scrum & Agile Development to Testing & Automated Deployments
              </li>
              <li>
              Supervised 5 undergraduate projects incorporating Agile practices.
              </li>
            </ul>
            </div>
          </div>
        </div>
      </div>
      <div>
        <h1 class="p-text-left" id="heading">
        Hughes Systique Corporation, Gurgaon, India (2016-2019)
        </h1>
        <div class="p-d-flex p-flex-column" id="experience">
          <div class="p-grid p-jc-between">
            <div class="p-text-left p-col-8">
            <h3 >ISRO Satellite Voice Gateway</h3>
            The project involved the development of a voice gateway unit for the
            Indian Space and Research Organisation's GSAT-6 satellite network that
            would allow terrestrial (telephone) calls over their satellite
            network.
            <ul>
              <li>
                Developed 2 out of 8 core modules involving satellite control
                transport and satellite resource management.
              </li>
              <li>
                Involved in the systems integration and deployment of the system
                at ISRO Earth Station, New Delhi, India.
              </li>
            </ul>
            </div>
            <div class="p-text-center p-col-4">
              <div style="width: auto" id="tech">
                  <Avatar
                    image="https://img.icons8.com/color/96/000000/c-programming.png"
                    size="xlarge"
                    shape="circle"
                    v-tooltip.bottom="'C Programming'"
                  />
                  <Avatar
                    image="https://img.icons8.com/dusk/64/000000/linux.png"
                    size="xlarge"
                    shape="circle"
                    v-tooltip.bottom="'Linux'"
                  />
                  <Avatar
                    image="https://img.icons8.com/color/100/000000/git.png"
                    size="xlarge"
                    shape="circle"
                    v-tooltip.bottom="'Git'"
                  />
              </div>
            </div>
            <div class="p-text-left p-col-8">
              <h3>Hotspot 2.0 Wifi Passpoint</h3>
              <ul>
                <li>
                  Migrated an existing licence validation server to a docker based
                  micro service.
                </li>
                <li>
                  Developed a micro service to encrypt and redirect application
                  logs.
                </li>
              </ul>
            </div>
            <div class="p-text-center p-col-4">
              <div style="width: auto" id="tech">
                  <Avatar
                    image="https://img.icons8.com/officel/40/000000/console.png"
                    size="xlarge"
                    shape="circle"
                    v-tooltip.bottom="'Shell Script'"
                  />
                  <Avatar
                    image="https://img.icons8.com/dusk/128/000000/docker.png"
                    size="xlarge"
                    shape="circle"
                    v-tooltip.bottom="'Docker'"
                  />
                  <Avatar
                    image="https://img.icons8.com/color/96/000000/c-programming.png"
                    size="xlarge"
                    shape="circle"
                    v-tooltip.bottom="'C programming'"
                  />
                  <Avatar
                    image="https://img.icons8.com/dusk/64/000000/linux.png"
                    size="xlarge"
                    shape="circle"
                    v-tooltip.bottom="'Linux'"
                  />
                  <Avatar
                    image="https://img.icons8.com/color/100/000000/git.png"
                    size="xlarge"
                    shape="circle"
                    v-tooltip.bottom="'Git'"
                  />
              </div>
            </div>
            <div class="p-text-left p-col-8">
              <h3 >Passive monitor for OTT media traffic</h3>
              The project involved the development of a system capable of monitoring
              an over-the-top media stream network at extremely high data rates by
              using the Data Plane Development Kit - DPDK.
              <ul>
                <li>
                  Designed and implemented a custom packet filtering algorithm along
                  with a user-based control-plane wrapper.
                </li>
                <li>Development and execution of test suites.</li>
              </ul>
            </div>
            <div class="p-text-center p-col-4">
              <div style="width: auto" id="tech">
                  <Avatar
                    image="https://img.icons8.com/color/96/000000/c-programming.png"
                    size="xlarge"
                    shape="circle"
                    v-tooltip.bottom="'C Programming'"
                  />
                  <Avatar
                    image="https://img.icons8.com/dusk/64/000000/linux.png"
                    size="xlarge"
                    shape="circle"
                    v-tooltip.bottom="'Linux'"
                  />
                  <Avatar
                    image="https://img.icons8.com/color/100/000000/git.png"
                    size="xlarge"
                    shape="circle"
                    v-tooltip.bottom="'Git'"
                  />
              </div>
            </div>
            <div class="p-text-left p-col-8">
              <h3 >High performance WIFI Access Point</h3>
              The project involved the development of a high performance
              WIFI-Access-Point image to support the multi-channel radio
              architecture.
              <ul>
                <li>Testing of custom ‘OpenWRT’ images</li>
                <li>
                  Development of module to configure the access point through
                  configuration files
                </li>
                <li>
                  Integration with a web interface to allow Restful configuration
                  and monitoring of the Access Point
                </li>
              </ul>
            </div>
            <div class="p-text-center p-col-4">
              <div style="width: auto" id="tech">
                  <Avatar
                    image="https://img.icons8.com/color/100/000000/git.png"
                    size="xlarge"
                    shape="circle"
                    v-tooltip.bottom="'Git'"
                  />
                  <Avatar
                    image="https://img.icons8.com/color/96/000000/c-programming.png"
                    size="xlarge"
                    shape="circle"
                    v-tooltip.bottom="'C Programming'"
                  />
                  <Avatar
                    image="https://img.icons8.com/dusk/64/000000/linux.png"
                    size="xlarge"
                    shape="circle"
                    v-tooltip.bottom="'Linux'"
                  />
                  <Avatar
                    image="https://img.icons8.com/dusk/150/000000/sql.png"
                    size="xlarge"
                    shape="circle"
                    v-tooltip.bottom="'SQL'"
                  />
              </div>
            </div>
          </div>
        </div>
      </div>  
    </ScrollPanel>
  </div>
</template>

<script>
export default {
  name: "Work",
};
</script>

<style >
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

#experience {
  font-size: 18px;
  margin-left : 2%;
  margin-right : 2%;
}
#heading{
  color: #dd700a;
  font-weight: bold;
}
#tech {
  position: relative;
  margin-top: 50px;
  margin-left: 15px;
  margin-right: 15px;
}
li{
  margin: 10px 0;
}
</style>